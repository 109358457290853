
.words-main {
    &, & * {
        box-sizing: border-box;
    }
    .form-main {
        padding: 0 10px;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
    }
    .data-main {
        width: 100%;
        height: calc(100% - 50px);
    }
}
