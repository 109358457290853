
.page-content {
    height: 40px;
    &, & * {
       box-sizing: border-box;
    }
    display: flex;
    justify-content: center;
    align-items: center;
}

